const treeSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={76}
    height={88}
    fill="none"
    {...props}
  >
    <path
      fill="#E76B47"
      fillRule="evenodd"
      d="M39.182 10.332c-3.553 5.727-6.496 10.41-6.541 10.407-.045-.003-.744-.81-1.554-1.794-.809-.984-2.815-3.18-4.456-4.881l-2.986-3.093-1.742 1.685c-3.396 3.283-8.277 9.074-11.519 13.664C5.361 33.433 2.157 40.224.897 46.428c-.49 2.408-.534 7.044-.09 9.241 1.736 8.578 7.038 15.172 14.657 18.228 1.257.504 3.46 1.093 5.117 1.367l.613.101V82.366H8.603V87.5h56.252v-5.134H54.226V69.191l10.637-.044 10.637-.044-4.689-7.53c-2.579-4.141-4.724-7.624-4.767-7.74-.06-.162.743-.221 3.462-.257l3.539-.046-5.322-8.552c-2.926-4.704-5.321-8.61-5.321-8.681 0-.071 1.309-.15 2.908-.176l2.908-.046L57.01 18.077C50.847 8.178 45.768.044 45.723 0c-.045-.043-2.989 4.606-6.541 10.332Zm13.311 9.884c3.651 5.866 6.639 10.697 6.639 10.735 0 .037-1.327.088-2.948.114l-2.948.047 5.382 8.642 5.383 8.642-3.498.046c-2.024.027-3.497.114-3.497.206 0 .088 1.888 3.186 4.194 6.885 2.308 3.699 4.444 7.13 4.748 7.623l.552.899H43.327l1.031-2.182c1.67-3.533 2.344-6.602 2.345-10.673 0-2.504-.245-4.38-.917-6.997-1.322-5.147-4.54-11.606-8.767-17.597l-1.127-1.597 4.22-6.772c2.322-3.725 4.513-7.259 4.87-7.854.358-.595.7-1.027.762-.958.061.068 3.098 4.924 6.75 10.79Zm-26.504.5c8.83 10.11 14.094 19.2 15.494 26.761.381 2.058.42 5.368.083 7.208-.863 4.722-3.347 8.89-7.027 11.795-2.12 1.673-5.082 3.05-7.586 3.527l-.853.162v-7.224l4.416-4.624 4.416-4.624-1.718-1.792-1.719-1.793-2.697 2.82L26.1 55.75V45.572h-4.906v10.179l-2.698-2.82-2.699-2.82-1.756 1.838-1.757 1.838 4.455 4.664 4.455 4.665v6.975l-.931-.114c-1.082-.133-3.427-.923-4.667-1.573-6.53-3.422-10.59-10.591-10.209-18.03.383-7.463 5.022-16.696 13.588-27.044 1.797-2.17 4.5-5.139 4.679-5.139.072 0 1.122 1.136 2.335 2.524ZM49.32 75.776v6.589H42.29V69.189H49.32v6.588Zm-11.937.77v5.819H26.1v-7l.613-.104c3.588-.604 7.168-2.03 9.683-3.858.498-.361.924-.661.946-.666.023-.005.041 2.61.041 5.81Z"
      clipRule="evenodd"
    />
  </svg>
)
export default treeSvg
