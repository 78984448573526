import { useState } from "react";
import Animation from "../animation/animation";
import ProgressImage from "../../images/rs-progress.png";

import "./progress.scss";

const ProgressVideo = ({ url, active, onClose }) => {
    const closeVideo = () => {
        onClose();
    };

    return (
        <div
            className={`progress-modal ${active ? "active" : ""}`}
            onClick={closeVideo}
        >
            {active && (
                <div className="progress-modal-wrapper">
                    <iframe
                        src={url}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                </div>
            )}
        </div>
    );
};

const Progress = () => {
    const [active, setActive] = useState(false);
    const [video, setVideo] = useState("");
    const [complex] = useState([
        {
            date: "Ноябрь 2023",
            url: "https://www.youtube.com/embed/q6K_cSLgljk?si=v7VCUz8APLrOAMSH",
        },
        // {
        //     date: "Июнь 2023",
        //     url: "https://www.youtube.com/embed/4kGSjol6NL4?si=yNis1UCI9fe1Ajpo",
        // },
        // {
        //     date: "Апрель 2023",
        //     url: "https://www.youtube.com/embed/2Oqjz1BvRQw?si=vrn0kUy4DyBL9TT7",
        // },
        // {
        //     date: "Февраль 2023",
        //     url: "https://www.youtube.com/embed/q322YEuvmrs?si=pzEwl6wQxVGe4FXZ",
        // },
        // {
        //     date: "Январь 2023",
        //     url: "https://www.youtube.com/embed/KwodDv7RyCA?si=m61XUlDiG0Lqv423",
        // },
    ]);

    const handleVideo = (url) => {
        setActive(true);
        setVideo(url);
    };

    const closeVideo = () => {
        setActive(false);
    };

    return (
        <section id="progress">
            <div className="inner">
                <Animation>
                    <h2>Ход строительства</h2>
                </Animation>
                <Animation>
                    <div className="progress-wrapper">
                        {complex.map((item, index) => (
                            <div className="progress-item" key={index}>
                                <div
                                    className="progress-item-poster"
                                    onClick={() => handleVideo(item.url)}
                                >
                                    <img src={ProgressImage} alt="poster" />
                                </div>
                                <div className="progress-item-bottom">
                                    <div className="progress-item-date">
                                        {item.date}
                                    </div>
                                    <div
                                        className="progress-item-play"
                                        onClick={() => handleVideo(item.url)}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="19"
                                            viewBox="0 0 18 19"
                                            fill="none"
                                        >
                                            <path
                                                d="M14.4496 10.6376C15.1935 10.062 15.1935 8.9389 14.4496 8.36329C12.2014 6.62374 9.691 5.25263 7.01234 4.30127L6.52299 4.12747C5.58678 3.79496 4.5979 4.42838 4.47116 5.39479C4.11704 8.09495 4.11704 10.9059 4.47116 13.6061C4.5979 14.5725 5.58678 15.2059 6.52299 14.8734L7.01234 14.6996C9.691 13.7483 12.2014 12.3772 14.4496 10.6376Z"
                                                fill="#c95c3e"
                                            />
                                        </svg>
                                        Смотреть видео
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Animation>
            </div>
            <ProgressVideo url={video} active={active} onClose={closeVideo} />
        </section>
    );
};

export default Progress;
